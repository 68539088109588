import Vue from 'vue';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../assets/css/main.css';
import '../assets/css/editor.css';
import '../assets/css/color-dark.css';
import ElTreeGrid from 'element-tree-grid'; // treeTable

Vue.component(ElTreeGrid.name, ElTreeGrid);

Vue.use(Element, { size: 'small' });
