import _ from 'lodash';

const { location } = window;
// 兼容location.origin
if (!location.origin) {
  const { host, protocol } = location;
  location.origin = `${protocol}//${host}`;
}

let defaultCachePrefix = '20180428_'; // 默认缓存前缀,便于快速清除缓存
export const setCatchePrefix = prefix => {
  defaultCachePrefix = `${prefix}_`;
};
const TOKEN = 'token';
/**
 * 获取token
 * @param forHeader
 * @returns {*}
 */
export function getToken(forHeader) {
  const token = localStorage[TOKEN];
  let res;
  if (token) {
    res = forHeader ? `Bearer ${token}` : token;
  } else {
    res = forHeader ? '' : token;
  }
  return Promise.resolve(res);
}
/**
 * 保存token
 * @param token
 * @param remeber
 */
export function saveToken(token, remeber) {
  (remeber ? localStorage : sessionStorage)[TOKEN] = token;
}
export const saveStorage = (key, value, remeber) => {
  (remeber ? localStorage : sessionStorage)[`${defaultCachePrefix}${key}`] = value;
};
export const getStorage = key =>
  sessionStorage[`${defaultCachePrefix}${key}`] || localStorage[`${defaultCachePrefix}${key}`];

export function removeToken() {
  sessionStorage.removeItem(TOKEN);
  localStorage.removeItem(TOKEN);
}

/**
 * token状态
 * @returns {boolean}
 */
export function loginStatus() {
  if (!getToken()) {
    return false;
  }
  return true;
}

/**
 * 对象转换成options数组
 * @param {*} obj
 */
export const obj2options = obj => _.map(obj, (label, value) => ({ value, label }));

const { config: { cdn: cdnUrl = '.' } = {} } = window;

/**
 * 静态资源cdn链接
 */
export { cdnUrl };
