<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
</style>
