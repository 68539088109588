import { post, get, getApiUrl } from 'util/request';

// 账户api
const userApiUrl = '/base/user';
// 书店api
const advisorApiUri = '/common/advisor';

/**
 * 登录
 * @param {*} user
 */
export const login = user => post('/common/login', user);

/**
 * 获取数据
 * @param {*}
 */
export const getData = user => get('/common/login', user);
/**
 * 获取登陆失败次数
 * @param {*} args
 */
export const getUserFaileCount = args => get('/common/userFaileCount', args);
/**
 * 验证码地址
 */
export const captchaUrl = `${getApiUrl()}/common/captcha`;
/**
 * modules列表获取
 * @param {} args
 */
export const userTypesInfoGet = args => post('/base/user/userTypesInfo', args);
/**
 * [账户] 获取当前用户详细信息
 * @param {*} args
 */
export const getUserInfo = args => get(`${userApiUrl}/userInfo`, args);
/**
 * 用户头像获取
 * @param {*} query
 */
export const userPhotoGet = query => get(`base/file/meta/${query.id}`);
/**
 * 下载资源
 */
export const getResource = ({ code }) => get(`/common/downx/${code}`);
/**
 * 文章信息获取
 * @param {*} args
 */
export const articleyGet = args => get(advisorApiUri, args);
/**
 * 文章信息获取
 * @param {*} args
 */
export const articleListGet = args => get(`${advisorApiUri}/list`, args);
/**
 * 分类信息获取
 * @param {*} args
 */
export const classifyGet = args => get(`${advisorApiUri}/classify`, args);
/* 文件上传 */
export const fileMeta = ids => get(`/base/file/meta/${ids}`);
// 获取源信息接口
export const uploadPath11 = args => fileMeta(args.id);
// 获取教材
export const goodsGet = args => {
  const { section = '', ...arg } = args;
  const where = [];
  if (section) {
    where.push({
      andWhere: [{ whereIn: ['grade', section === '1' ? ['1', '2', '3', '4', '5', '6'] : ['7', '8', '9']] }],
    });
  }
  return get('/common/textbook/good', { _where: JSON.stringify(where), ...arg });
};
// 下载统计
export const downPost = args => get('/common/textbook/down', args);
// 学年学期(需要登录不可用)
export const semesterAndYearGet = () => get('/base/semesterAndYear');

/**
 * 获取公共数据源
 * @param {*} bucket
 */
export const getCommentCache = bucket => get('res/common/kc', bucket);
/* 获取字典信息(数据结构改了) */
export const getInit = args => get('/res/common/cd', args);
/**
 * 获取教参章节
 * @param {*} args(grade,subject,publishers:y(出版社),fascicule:1(分册),status:1)
 */
export const chapterGet = args => get('res/common/cp', args);
/* 获取来源(来源获取:term=source,分类获取:term=classify,上传文件类型:term=exts) */
export const sourceGet = args => get('res/common/kc', args);
/**
 * 资源类型
 * @param {*} data
 */
export const fileTypeGet = () => get('/common/textbook/fileType');
/**
 * 获取教参
 * @param {*} query
 */

export const userResGet = query => get('res/common/res', query);
