export default [
  {
    path: '/',
    redirect: '/index',
    meta: { public: true },
    component: () => import('../main/index.vue'),
  },
  {
    path: '/index',
    name: 'index',
    meta: { public: true },
    component: () => import('../main/index.vue'),
  },
  // 数字教材
  {
    path: '/jiaocai',
    name: 'jiaocai',
    meta: { public: true },
    component: () => import('../main/book/jiaocai.vue'),
  },
  // 数字教参
  // {
  //   path: '/jiaocan',
  //   name: 'jiaocan',
  //   meta: { public: true },
  //   component: () => import('../main/book/jiaocan.vue'),
  // },
  // 帮助
  {
    path: '/help',
    name: 'helpme',
    meta: { public: true },
    component: () => import('../main/help.vue'),
  },
  // 帮助详情
  {
    path: '/help/:classId',
    name: 'help',
    meta: { public: true },
    component: () => import('../main/helpinfo.vue'),
  },
  // 智慧应用
  {
    path: '/application',
    name: 'apply',
    meta: { public: true },
    component: () => import('../main/apply.vue'),
  },
  // 大数据测评
  {
    path: '/assessment',
    name: 'assessment',
    meta: { public: true },
    component: () => import('../main/assessment.vue'),
  },
  // 人工智能
  {
    path: '/ai',
    name: 'ai',
    meta: { public: true },
    component: () => import('../main/ai.vue'),
  },
  // 听力
  {
    path: '/hearing',
    name: 'hearing',
    meta: { public: true },
    component: () => import('../main/hearing.vue'),
  },
  // 听力播放
  {
    path: '/play',
    name: 'play',
    meta: { public: true },
    component: () => import('../main/play.vue'),
  },
  // 通知
  {
    path: '/notice',
    name: 'notice',
    meta: { public: true },
    component: () => import('../main/notice.vue'),
  },
  // 文章详情
  {
    path: '/article',
    name: 'articleDetails',
    meta: { public: true },
    component: () => import('../main/articleDetails.vue'),
  },
  // 下载
  {
    path: '/download',
    name: 'download',
    meta: { public: true },
    component: () => import('../main/download.vue'),
  },
  // 数字资源 resource
  {
    path: '/resource',
    name: 'resource',
    meta: { public: true },
    component: () => import('../main/book.vue'),
  },
  // 豫教听读 resource
  {
    path: '/yujiao',
    name: 'yujiao',
    meta: { public: true },
    component: () => import('../main/yujiao.vue'),
  },
];
