import _ from 'lodash';
import Vue from 'vue';
import { saveToken, getStorage, saveStorage, setCatchePrefix } from 'util';
import { login, getUserInfo, userTypesInfoGet } from '../../main/api.js';
import router from '../../router/index';

// 定义新窗口打开的页面：
const state = {
  name: '',
  user: {},
  modules: [],
  isLogin: false,
  appDict: {},
  isFullScreen: false,
  activeIndex: 0,
  schools: [],
  items: [],
  id: '',
  key: '',
  userType: 'welcome',
  userStorage: {},
};
const getters = {};
const actions = {
  login({ commit, dispatch }, payload) {
    const { remember, ...data } = payload;
    return login(data).then(({ data: { user, token } = {}, err }) => {
      let status = false;
      if (!err) {
        commit('upState', { user, isLogin: true });
        setCatchePrefix(user.id);
        saveToken(token, remember);
        const { userTypes } = user;
        router.push('/welcome');
        if (userTypes.length === 1) {
          // 单角色用户
          const userType = (_.head(userTypes) || {}).code;
          dispatch('getUserTypesInfo', { userType });
        } else {
          saveStorage('userType', 'welcome', true);
        }
        status = true;
      } else {
        // 提示错误信息
        Vue.prototype.$message.error(err.message);
        status = false;
      }
      return { status };
    });
  },
  relogin({ commit }) {
    return getUserInfo().then(({ data: user, err }) => {
      if (!err) {
        // setCatchePrefix(user.id);
        // if (userType === 'welcome') {
        //   const { userTypes = [] } = user;
        //   if (userTypes.length === 1) {
        //     userType = _.head(userTypes).code;
        //   }
        // } else {
        //   userType = getStorage('userType');
        // }
        commit('upState', { user, isLogin: true });
        router.push('/welcome');
        // dispatch('getUserTypesInfo', { userType });
      }
    });
  },
  getUserTypesInfo({ commit, state }, { userType }) {
    const initUserStorage = getStorage('userStorage');
    const userStorage = initUserStorage ? JSON.parse(initUserStorage) : state.userStorage;
    const { activeItemId } = userStorage[userType] || {};
    if (userType === 'welcome') {
      commit('upState', { userType, userStorage });
      saveStorage('userType', userType, true);
      saveStorage('userStorage', JSON.stringify(userStorage), true);
      return router.push('/welcome');
    }
    return userTypesInfoGet({ userType, itemId: activeItemId }).then(({ data: { modules, items, id, key }, err }) => {
      if (err) Vue.prototype.$message.error(err.message);
      if (_.isEmpty(userStorage[userType])) {
        userStorage[userType] = {
          tags: [],
          activeItemId: id,
        };
      }
      saveStorage('userType', userType, true);
      saveStorage('userStorage', JSON.stringify(userStorage), true);
      commit('upState', { modules, items, id, key, userType, userStorage });
    });
  },
  changeItem({ commit, state }, { payload }) {
    const { userType } = state;
    const { itemId } = payload;
    return userTypesInfoGet({ itemId, userType }).then(({ data: { modules, items, id, key }, err }) => {
      if (err) Vue.prototype.$message.error(err.message);
      router.push('/home');
      const userStorage = _.cloneDeep(state.userStorage);
      userStorage[userType] = {
        tags: [],
        activeItemId: itemId,
      };
      commit('upState', {
        modules,
        items,
        id,
        key,
        userType,
        userStorage,
      });
      saveStorage('userStorage', JSON.stringify(userStorage), true);
    });
  },
};
const mutations = {
  upState(state, payload) {
    _.forEach(payload, (v, k) => {
      _.set(state, k, v);
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
