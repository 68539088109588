const { location, config: { api: apiUrl, cdn: cdnUrl } = {} } = window;
let urlinfo = `${cdnUrl}/download/1.0.0/client/release.json`;
let hnszjcUrl = `${cdnUrl}/download/hnszjc/1.0.0/client/release.json`;
let ytUrl = `${cdnUrl}/download/yt/1.0.0/client/release.json`;
// 首页轮播图json
let siteBannerJson = `${cdnUrl}/site-banner/release.json`;
if (!(urlinfo.indexOf('//') === 0 || urlinfo.indexOf('http://') === 0 || urlinfo.indexOf('https://') === 0)) {
  urlinfo = location.origin + urlinfo;
  hnszjcUrl = location.origin + hnszjcUrl;
  ytUrl = location.origin + ytUrl;
}
if (
  !(
    siteBannerJson.indexOf('//') === 0 ||
    siteBannerJson.indexOf('http://') === 0 ||
    siteBannerJson.indexOf('https://') === 0
  )
) {
  siteBannerJson = location.origin + siteBannerJson;
}
// 听力json
// let listenUrl = `${cdnUrl}/english-listening/list.json`;
let listenUrl = '/api/res/common/listening/list';
if (!(urlinfo.indexOf('//') === 0 || urlinfo.indexOf('http://') === 0 || urlinfo.indexOf('https://') === 0)) {
  urlinfo = location.origin + urlinfo;
}
// 如果前缀不是 [//]、[http://]、[https://] 路由拼接
if (!(listenUrl.indexOf('//') === 0 || listenUrl.indexOf('http://') === 0 || listenUrl.indexOf('https://') === 0)) {
  listenUrl = location.origin + listenUrl;
}
console.log('listenUrl ==========> ', listenUrl);
window.config = {
  siteBannerInfo: {
    descr: '首页轮播图图片地址,json地址',
    downUrl: `${cdnUrl}/site-banner/`,
    urlinfo: siteBannerJson,
  },
  api: apiUrl,
  cdn: cdnUrl,
  listenUrl,
  downloadWindows: {
    title: '大数据学科质量测评Windows版',
    descr: '适用于Windows 7 及以上版本',
    downUrl: `${cdnUrl}/download/1.0.0/client/`,
    urlinfo,
    name: '数字教材应用云.exe',
  },
  hnszjcDownload: {
    downUrl: 'https://cdn.henanyuexue.com/static/download/1.0.0/client/',
    hnszjcUrl,
    name: '数字教材.exe',
  },
  downloadAndroid: {
    title: '数字教材应用云Android版',
    version: '版本：V1.0.0',
    descr: '适用于Windows 7 及以上版本',
    updateTime: '2018-12-3',
    size: '30.6MB',
    url: '/#/assets/down/window1.0.0.exe',
    name: 'window1.0.0.exe',
  },
  downloadApple: {
    title: '数字教材应用云IOS版',
    version: '版本：V1.0.0',
    descr: '适用于Windows 7 及以上版本',
    updateTime: '2018-12-3',
    size: '30.6MB',
    url: '/#/assets/down/window1.0.0.exe',
    name: 'window1.0.0.exe',
  },
  downloadOther1: {
    title: '佳能高速扫描仪6030C驱动',
    descr: '适用于Windows 7 及以上版本',
    updateTime: '',
    size: '66.5MB',
    url: `${cdnUrl}/drives/6030c.zip`,
    name: '高速扫描仪6030C驱动',
  },
  downloadOther4: {
    title: '佳能高速扫描仪G2090驱动',
    descr: '适用于Windows 7 及以上版本',
    updateTime: '',
    size: '319MB',
    url: `${cdnUrl}/drives/G2090.zip`,
    name: '高速扫描仪G2090驱动',
  },
  downloadOther5: {
    title: '柯达高速扫描仪i3000系列驱动',
    descr: '适用于Windows 7 及以上版本',
    updateTime: '',
    size: '107MB',
    url: `${cdnUrl}/drives/i3000.zip`,
    name: '高速扫描仪i3000系列驱动',
  },
  downloadOther2: {
    title: '数字教材应用云Android版',
    descr: '适用于Windows 7 及以上版本',
    updateTime: '',
    size: '',
    url: '/#/assets/down/window1.0.0.exe',
    name: 'window1.0.0.exe',
  },
  downloadOther3: {
    title: '谷歌浏览器Chrome',
    descr: '适用于Windows 7 及以上版本',
    url: 'https://www.google.cn/chrome/',
    name: '谷歌浏览器Chrome',
  },
  downloadYujiao: {
    downUrl: `${cdnUrl}/download/yt/1.0.0/client/`,
    urlinfo: ytUrl,
    name: '豫教听读.exe',
  },
  // 英语听力下载地址
  EnglishDownUrl: 'https://pan.baidu.com/s/1JCEX6Il5xgu9N2owX2jftw',
  // 英语听力下载提取码
  EnglishDownCode: 'q86l',
  // 登录链接
  loginUrl: '/auth/',
  // 线上跳转地址 登录注册忘记密码
  ssoUrlOnline: 'https://sso.szjcyyy.com/index.html',
  // 注册链接
  registerUrl: '/auth/#/register',
  // 忘记密码链接
  forgetUrl: '/auth/#/forget',
  // 用户空间链接
  applicationUrl: '/auth/#/application',
  // 框架链接
  basicIndexUrl: '/main/#/welcome',
  // 腾讯吐槽产品ID
  txProductId: 54258,
  // 腾讯吐槽无登录时跳转路径
  txNologinUrl: 'https://support.qq.com/products/54258',
  // 意见反馈地址
  txFeedback: 'https://support.qq.com/products/54258/faqs-more',
  // 客服qq
  kfQQ: '206785497',
  // 客服在线时间
  kfOnlineTime: '周一至周五 9:00-17:30',
  // 客服电话
  kfTel: '0371-63952358',
  // 联系邮箱
  contactEmail: 'support@hnszjc.com',
  // 移动端首页
  appURL: '/mobile',
  // 河南省教育厅
  hnJiaoYuTing: 'http://jyt.henan.gov.cn/',
  // 河南省中小学数字教材平台
  hnZhongXiaoXue: 'http://www.hnszjc.com/index.html',
  // 河南省基础教育资源公共服务平台
  hnJiChuJiaoYu: 'http://www.hner.cn/',
  // 河南悦学网络科技有限公司
  hnYueXue: 'http://www.henanyuexue.com/',
  // 河南省新华书店发行集团有限公司
  hnBook: 'http://www.hnxhbook.com.cn/',
  // 备案号(豫ICP备16022426号-4)
  beiAnHao: 'https://beian.miit.gov.cn/',
  // 河南省中小学数字教材服务平台下载
  szjcDown: 'http://hnszjc.com/download.html',
};
/* 判断是移动端还是pc端 */
const webReg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
if (navigator.userAgent.match(webReg)) {
  // 移动端
  window.location.href = window.config.appURL;
}
