import Vue from 'vue';
import { getToken } from 'util';
import _ from 'lodash';
import { setToken } from 'util/request';
import app from './app.vue';
import router from './router/index';
import store from './store';
import './plugins/element';
import './config';

// 获取cookie,判断是否是登录状态
const { cookie } = document;
Vue.prototype.cookie = _.last(_.split(_.filter(_.split(cookie, ';'), v => v.indexOf('token=') > -1), 'token='));
Vue.prototype.appId = '4f6dbca0aad94e6ba7ce662ecec25b0b';
setToken(getToken);
new Vue({
  router,
  store,
  render: h => h(app),
}).$mount('#app');

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  next();
});
