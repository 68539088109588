import Vue from 'vue';
import Router from 'vue-router';
import { getToken } from 'util';
import routes from './routers';

Vue.use(Router);
const router = new Router({
  routes,
});

// 使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  if (to.meta.public) {
    // 不需鉴权的页面
    next();
    return;
  }
  return getToken().then(token => {
    if (!token) {
      // 未登陆
      next();
    } else {
      next();
    }
  });
});

export default router;
